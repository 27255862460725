/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from "react-router-dom";

/** Components */
import GridCore from '../GridCore.js';
import GridCellPicTextPicLink from './../../Cells/GridCellPicTextPicLink';
import GridCellCompareValues from './../../Cells/GridCellCompareValues';
import GridCellPictos from './../../Cells/GridCellPictos';
import GridCellStatusAction from './../../Cells/GridCellStatusAction';

/** Helpers */
import { sortByWithNullValue } from './../../../../helpers/sort.js';
import { getUpdateOrAutoValue } from './../../../../helpers/datas.js';
import { updateFilterDatas } from './../../../../helpers/filters.js';
import { updateURLSearchParams } from './../../../../helpers/functions';
import { 
  getMinMaxFromArrayObject, 
  isValidJSON, 
  isNullOrUndefined,
  getUrlPart
} from './../../../../helpers/functions.js';

/** SCSS */
import './GridCoreKeywords.scss';

function GridCoreKeywords( props )
{
  /** Get props */
  const {
    displayMode,
    loadingParams,
    gridSelectedKeywordsGroups,
    callBackFunction,
    reloadGridCallBackFct
  } = props;

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  /** Init State */
  const [ dataSource, setDataSource ] = useState( null );
  const [ callBackParams, setCallBackParams ] = useState( null );

  /** UseSearchParams Hook */ 
  const [ searchParams, setSearchParams ] = useSearchParams();
  
  /** Define page size */
  const pageSize = 25;

  /** Define data fields */
  const dataFields = [
    { name: 'label', dataType: 'string' },
    { name: 'status_action', map: 'status_action', dataType: 'string' },
    { name: 'gsc_urls', map: 'gsc.urls', dataType: 'string' },
    { name: 'volume_value', map: 'volume.value', dataType: 'number' },
    { name: 'volume_variation', map: 'volume.variation', dataType: 'number' },
    { name: 'impressions_value', map: 'gsc.impressions.value', dataType: 'number' },
    { name: 'impressions_variation', map: 'gsc.impressions.variation', dataType: 'number' },
    { name: 'clicks_value', map: 'gsc.clicks.value', dataType: 'number' },
    { name: 'clicks_variation', map: 'gsc.clicks.variation', dataType: 'number' },
    { name: 'ctr_value', map: 'gsc.ctr.value', dataType: 'number' },
    { name: 'ctr_variation', map: 'gsc.ctr.variation', dataType: 'number' },
    { name: 'position_value', map: 'serp.position.value', dataType: 'number' },
    { name: 'position_variation', map: 'serp.position.variation', dataType: 'number' },
    { name: 'rate_value', map: 'serp.rate.value', dataType: 'number' },
    { name: 'rate_variation', map: 'serp.rate.variation', dataType: 'number' },
    { name: 'snippets', map: 'serp.snippets', dataType: 'string' }
  ];

  /** Define data fields to load */
  const dataFieldsToLoad = [ 
    'keywords.followed',
    'keywords.gsc',
    'keywords.groups',
    'keywords.status',
    'keywords.expectedUrl',
    'serpHtmlUrl',
    'volume',
    'gsc.impressions',
    'gsc.clicks',
    'gsc.urls',
    'serp.position',    
    'serp.SEO1PixPos',
    'serp.SEORate',
    'serp.rate',
    'serp.snippets'
  ];

  /** Define grid columns properties */
  const gridColumns = [
    { 
      label: 'Mots-clés', 
      dataField: 'label',
      align: 'left',
      allowHide: false,
      cellsClassName: 'keywords',
      template: formatObject => 
      {
        /** Define on click function to filter on label */
        let currentValues = {};
        let currentFilters = !isNullOrUndefined( JSON.parse( formatObject.value ).currentFilters ) ? 
          JSON.parse( JSON.parse( formatObject.value ).currentFilters )
          : [];
       
        // with existing filters
        if( currentFilters.length > 0  )
        {
          // get current label filter params
          const labelFilterIndex = currentFilters.findIndex( elem => elem.filterName === 'label' );

          if( labelFilterIndex > -1 )
          {
            currentValues = {
              ...currentFilters[labelFilterIndex].values,
              value: {
                value: JSON.parse( formatObject.value ).value,
                filterStringType: "equalsIgnoreCase"
              }
            }            
          }
        
        // without existing filters
        } else {

          currentValues = { 
            value: {
              value: JSON.parse( formatObject.value ).value,
              filterStringType: "equalsIgnoreCase"
            }
          };
        }

        // build filter url param
        const filterUrlParam = updateFilterDatas( 
          currentFilters,
          { value: 'string', keywordsGroups: 'keywords-groups' },
          'label',
          'label',
          'Mot-clé',
          currentValues
        );

        // apply template
        formatObject.template = GridCellPicTextPicLink( formatObject.value !== null ? 
          { 
            text: JSON.parse( formatObject.value ).value,
            onClickFct: () => updateURLSearchParams( { filters: JSON.stringify( filterUrlParam ) }, [ searchParams, setSearchParams ] ),
            postPic: JSON.parse( formatObject.value ).link !== undefined ? 'GoogleLink' : undefined,
            postPicOptions: JSON.parse( formatObject.value ).link !== undefined ? { width: 24, height: 24 } : undefined,
            postPicLink: JSON.parse( formatObject.value ).link
          }
          : null 
        );
      }
    },{ 
      label: 'URL', 
      dataField: 'gsc_urls',
      align: 'left',
      width: '15%',
      template: formatObject => {
        formatObject.template = GridCellPicTextPicLink( formatObject !== null ? 
          { 
            text: Array.isArray( JSON.parse( formatObject.value ).value ) ? 
              getUrlPart( JSON.parse( formatObject.value ).value[0].url, 'pathname' )
              : undefined,
            textLink: Array.isArray( JSON.parse( formatObject.value ).value ) ? 
              JSON.parse( formatObject.value ).value[0].url 
              : undefined,
            postPic: Array.isArray( JSON.parse( formatObject.value ).value ) && JSON.parse( formatObject.value ).value[0].isExpectedUrl === false ? 
              'ExclamationMark' 
              : undefined,                
            postPicOptions: Array.isArray( JSON.parse( formatObject.value ).value ) && JSON.parse( formatObject.value ).value[0].isExpectedUrl === false ? 
              { width: 16, height: 16, fill: 'white', title: JSON.parse( formatObject.value ).value[0].expectedUrl } 
              : undefined
          }
          : null 
        );
      }
    },{ 
      label: 'Vol.', 
      dataField: 'volume_value', 
      align: 'center',
      width: 80,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null, notation: 'compact' } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 ) 
    },{ 
      label: '', 
      dataField: 'volume_variation', 
      showIcon: true,
      align: 'center',
      width: 60,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value, notation: 'compact' } );
      }
    },{ 
      label: 'Imp.', 
      dataField: 'impressions_value', 
      align: 'center',
      width: 80,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null, notation: 'compact' } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 )
    },{ 
      label: '', 
      dataField: 'impressions_variation', 
      showIcon: true,
      align: 'center',
      width: 60,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value, notation: 'compact' } );
      }
    },{ 
      label: 'Clics', 
      dataField: 'clicks_value', 
      align: 'center',
      width: 80,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null, notation: 'compact' } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 )
    },{ 
      label: '', 
      dataField: 'clicks_variation', 
      showIcon: true,
      align: 'center',
      width: 60,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value, notation: 'compact' } );
      }
    },{ 
      label: 'CTR', 
      dataField: 'ctr_value', 
      align: 'center',
      width: 80,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null, style: 'percent' } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 )
    },{ 
      label: '', 
      dataField: 'ctr_variation', 
      showIcon: true,
      align: 'center',
      width: 60,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value, notation: 'compact' } );
      }
    },{ 
      label: 'Pos. SERP', 
      dataField: 'position_value', 
      align: 'center',
      width: 80,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 ) 
    },{ 
      label: '', 
      dataField: 'position_variation', 
      showIcon: true,
      align: 'center',
      width: 60,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value } );
      }
    },{ 
      label: 'Occ.', 
      dataField: 'rate_value', 
      align: 'center',
      width: 80,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: formatObject.value, variation: null, style: 'percent' } );
      },
      sortComparator: (value1, value2) => sortByWithNullValue( value1, value2 ) 
    },{ 
      label: '', 
      dataField: 'rate_variation', 
      showIcon: true,
      align: 'center',
      width: 60,
      template: formatObject => {
        formatObject.template = GridCellCompareValues( { value: null, variation: formatObject.value } );
      }
    },{ 
      label: 'Snippets', 
      dataField: 'snippets', 
      align: 'left',
      width: 126,
      allowSort: false,
      cellsWrap: true,
      template: formatObject => {
        formatObject.template = GridCellPictos( 
          isValidJSON( formatObject.value ) ? 
            { ...JSON.parse( formatObject.value ), id: formatObject.row.id, pageSize: pageSize, index: formatObject.row.index } 
            : { value: null, id: formatObject.row.id, pageSize: pageSize, index: formatObject.row.index } 
          );
      }
    },{ 
      label: 'Statut / Action', 
      dataField: 'status_action', 
      align: 'center',
      width: 110,
      allowSort: false,
      cellsWrap: true,
      template: formatObject => {
        formatObject.template = GridCellStatusAction(
          {
            id: formatObject.row.id, 
            index: formatObject.row.index,
            pageSize: pageSize,
            statusId: isValidJSON( formatObject.value ) ?
              JSON.parse( formatObject.value ).value
              : undefined,
            statusDatas: userDatas.status,
            label: isValidJSON( formatObject.value ) ?
              JSON.parse( formatObject.value ).label
              : undefined,
            loadingParams: {
              clientID: loadingParams.clientID,
              device: loadingParams.device,
              location: loadingParams.location,
              currentPeriod: loadingParams.currentPeriod
            },
            reloadGridCallBackFct: reloadGridCallBackFct
          } 
        );
      }
    },
  ];

  /** Format data source for Grid component */
  const formatDataSourceFct = response => response.map( elem => ({ 
    ...elem, 
    label: JSON.stringify( { value: elem.label, link: elem.serpHtmlUrl, keywordsGroups: elem.keywordsGroups, currentFilters: searchParams.get('filters') } ), 
    volume: elem?.volume ? 
      { 
        value: elem.volume.currentVolume === null ? NaN : elem.volume.currentVolume, 
        variation: elem.volume.variation === null ? NaN : elem.volume.variation
      }
      : { value: null, variation : null }, 
    gsc: { 
      clicks: elem?.gsc?.clicks ? 
        { 
          value: elem.gsc.clicks.currentValue === null ? NaN : elem.gsc.clicks.currentValue, 
          variation: elem.gsc.clicks.variation === null ? NaN : elem.gsc.clicks.variation
        } 
        : { value: NaN, variation: NaN },
      impressions: elem?.gsc?.impressions ? 
        { 
          value: elem.gsc.impressions.currentValue === null ? NaN : elem.gsc.impressions.currentValue, 
          variation: elem.gsc.impressions.variation === null ? NaN : elem.gsc.impressions.variation
        } 
        : { value: NaN, variation: NaN },
      ctr: !isNullOrUndefined( elem?.gsc?.clicks ) && !isNullOrUndefined( elem?.gsc?.impressions ) ? 
        { 
          value: (
            !isNullOrUndefined( elem?.gsc?.clicks?.currentValue )
            && !isNullOrUndefined( elem?.gsc?.impressions?.currentValue ) 
          ) ? 
            elem.gsc.clicks.currentValue / elem.gsc.impressions.currentValue
            : NaN,
          variation: (
            !isNullOrUndefined( elem?.gsc?.clicks?.currentValue )
            && !isNullOrUndefined( elem?.gsc?.impressions?.currentValue ) 
            && !isNullOrUndefined( elem?.gsc?.clicks?.compareValue ) 
            && !isNullOrUndefined( elem?.gsc?.impressions?.compareValue ) 
          ) ?
            Intl.NumberFormat( 
              'fr-FR', 
              { maximumFractionDigits: 1 }
            ).format( 
              ( ( elem.gsc.clicks.compareValue / elem.gsc.impressions.compareValue ) - ( elem.gsc.clicks.currentValue / elem.gsc.impressions.currentValue ) ) * 100
            ) 
            : NaN
        }
        : { value: NaN, variation: NaN },
      urls: !isNullOrUndefined( elem?.gsc?.urls ) ? 
        JSON.stringify({ 
          value: elem.gsc.urls.map( url => ({
            url: url.url,
            click: url.click,
            expectedUrl: !isNullOrUndefined( elem?.expectedUrl ) ? 
              getUpdateOrAutoValue( elem.expectedUrl, 'expectedUrl' )
              : null,
            isExpectedUrl: !isNullOrUndefined( elem?.expectedUrl ) ? 
              url.url === getUpdateOrAutoValue( elem.expectedUrl, 'expectedUrl' ) ? true : false
              : null
          }))
        }) 
        : JSON.stringify( { value: null } )
    }, 
    serp: { 
      position : elem?.serp?.position ? 
        { 
          value: 
            elem.serp.position.currentValue === null ? 
              elem.serp.position.compareValue !== null ? 
                101
                : NaN 
              : elem.serp.position.currentValue,
          movement:
            ( elem.serp.position.currentValue !== null && elem.serp.position.compareValue === 101 ) ? 
              'in' 
            : ( elem.serp.position.currentValue === null && elem.serp.position.compareValue !== null ) ?
              'out'
            : null, 
          variation: 
            elem.serp.position.variation !== null ? 
              Math.floor( elem.serp.position.variation )
              : NaN         
        }
        : { value: NaN, variation: NaN },
      SEO1PixPos: elem?.serp?.SEO1PixPos ?
        {
          value: elem.serp.SEO1PixPos.currentValue === null ? NaN : -Math.round( elem.serp.SEO1PixPos.currentValue ), 
          variation: elem.serp.SEO1PixPos.variation === null ? NaN : Math.round( elem.serp.SEO1PixPos.variation )
        }
        : { value: NaN, variation: NaN },
      SEORate: elem?.serp?.SEORate ?
        {
          value: elem.serp.SEORate.currentValue === null ? NaN : elem.serp.SEORate.currentValue, 
          variation: elem.serp.SEORate.variation === null ? NaN : elem.serp.SEORate.variation
        }
        : { value: NaN, variation: NaN },
      rate: elem?.serp?.rate ?
        {
          value: elem.serp.rate.currentValue === null ? NaN : elem.serp.rate.currentValue, 
          variation: elem.serp.rate.variation === null ? NaN : elem.serp.rate.variation
        }
        : { value: NaN, variation: NaN },
      snippets:
        elem?.serp?.snippetsMe && elem?.serp?.snippetsOthers ?
          JSON.stringify( { value: { valueMe: elem.serp.snippetsMe.currentValue, valueOthers: elem.serp.snippetsOthers.currentValue } } )
        : elem?.serp?.snippetsMe ?
          JSON.stringify( { value: { valueMe: elem.serp.snippetsMe.currentValue, valueOthers: null } } )
        : elem?.serp?.snippetsOthers ?
          JSON.stringify( { value: { valueMe: null, valueOthers: elem.serp.snippetsOthers.currentValue } } )
        : !isNullOrUndefined( elem?.serp ) ?
          JSON.stringify( { value: { valueMe: null, valueOthers: null } } )
        : JSON.stringify( { value: null } )
    }, 
    categories: elem?.categories?.category1 && elem?.categories?.category2 && elem?.categories?.category3 ?
      { value: elem.categories.category1 + '|' + elem.categories.category2 + '|' + elem.categories.category3 }
      : null,
    status_action: !isNullOrUndefined( elem?.status ) ? 
      JSON.stringify({ value: elem.status.statusId, label: elem.label  })
      : JSON.stringify( { value: null, label: elem.label } )
  }));
  
  /** Update call back params */
  useEffect( () => 
  {
    if( dataSource !== null )
    {
      // call callback function with datas for parents elements
      setCallBackParams({
        ...callBackParams,
        gridComponentLoading: dataSource.gridComponentLoading,
        keywords: dataSource.nonFilteredDataSource.length === dataSource.filteredDataSource.length ? 
          'allKeywords'
          : dataSource.filteredDataSource.map( elem => JSON.parse( elem.label ).value ),
        selectedKeywords: dataSource.selectedKeywords,
        labels: dataSource.nonFilteredDataSource.map( elem => JSON.parse( elem.label ).value ),
        keywordsGroups: [ ...new Set( 
          dataSource.nonFilteredDataSource.filter( elem => 
            elem.keywordsGroups 
          ).map( elem => 
            elem.keywordsGroups 
          ).flat() 
        )],
        urls: [ ...new Set( 
          dataSource.nonFilteredDataSource.map( elem => 
            !isNullOrUndefined( elem?.gsc?.urls ) 
            && Array.isArray( JSON.parse( elem.gsc.urls ).value ) ? 
              JSON.parse( elem.gsc.urls ).value.map( elem => elem.url )
              : null 
          ).filter( elem => 
            elem !== null 
          ).flat()
        )],
        minMaxValues: getMinMaxFromArrayObject( 
          dataSource.nonFilteredDataSource, 
          [ 
            'volume.value',
            'gsc.clicks.value',
            'serp.position.value'
          ]
        )
      });
    }

  }, [ dataSource ]);

  /** Send callback params with callback function */
  useEffect( () => 
  {
    if( typeof callBackFunction === 'function' && callBackParams !== null )
      callBackFunction( callBackParams );

  }, [ callBackParams ]);

  return(
    <GridCore
      coreType='keywords'
      gridColumns={ gridColumns }
      dataFields={ dataFields }
      loadingParams={{
        clientID: loadingParams.clientID,
        device: loadingParams.device,
        location: loadingParams.location,
        currentPeriod: loadingParams.currentPeriod,
        comparePeriod: loadingParams.comparePeriod,
        userId: loadingParams.userId,
        filters: loadingParams.filters,
        dataFieldsToLoad: dataFieldsToLoad,
        reloadGridRandomIndex: loadingParams.reloadGridRandomIndex
      }}
      displayMode={ displayMode }
      gridSelectedKeywordsGroups={ gridSelectedKeywordsGroups }
      formatDataSourceFct={ formatDataSourceFct }
      callBackFct={ dataSource => setDataSource( dataSource ) }
      pageSize={ pageSize }
      columnHeight={ 72 }
      rowHeight={ 50 }
      pagerHeight={ 50 }
      pageSizeSelector={ true }
    />
  );
}

export default GridCoreKeywords;